<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <a-row class="header-solid ant-card" :gutter="24">
   <a-table :data-source="data">
      <a-table-column
        key="id"
        title="ID"
        data-index="id"
      />
      <a-table-column
        key="fullname"
        title="نام"
        data-index="fullname"
      />
      <a-table-column
        key="username"
        title="نام کاربری"
        data-index="username"
      />
    <a-table-column key="phone_number" title="شماره تلفن" data-index="phone_number" />
    <a-table-column key="email" title="ایمیل" data-index="email" />
      <a-table-column
        key="is_online"
        title="آنلاین"
        data-index="is_online"
      >
        <template slot-scope="is_online">
          <a-tag v-if="is_online" color="green">
            آنلاین
          </a-tag>
          <a-tag v-if="!is_online" color="red">
            آفلاین
          </a-tag>
        </template>
      </a-table-column>
    <a-table-column
      key="roles"
      :title="`گروه کاربری ${tagsFilterDropdownVisible ? ' (درحال فیلتر)' : ''}`"
      data-index="roles"
      :filtered-value="filteredTags"
      @filter="(value, record) => Object.values(record.roles).includes(value)"
      :filter-dropdown-visible.sync="tagsFilterDropdownVisible"
    >
      <template slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys }">
        <div style="background-color: white; border: #333 1px solid; padding:5px;border-radius: 10px;text-transform: uppercase;">
          <a-checkbox
            style="display: block; margin: 0px;"
            v-for="group in userGroups"
            :key="group"
            :checked="filteredTags.includes(group)"
            @change="filteredTags = toggleSelectedTags(filteredTags, group, $event.target.checked)"
          >
            {{ group }}
          </a-checkbox>
        </div>
      </template>
      <template slot-scope="roles">
        <span>
          <a-tag v-for="(role, id) in roles" :key="id" color="blue">{{ role }}</a-tag>
        </span>
      </template>
    </a-table-column>
    <a-table-column key="action" title="انجام بدید">
      <template slot-scope="text, record">
        <span>
          <a>مسدود سازی 一 {{ record.firstName }}</a>
          <a-divider type="vertical" />
          <a>حذف</a>
          <a-divider type="vertical" />
          <a @click="() => (displayModifyModal(record))">ویرایش</a>
        </span>
      </template>
    </a-table-column>
  </a-table>

  <a-modal
    v-model="modifyModel"
    :title="'ویرایش '+ modifyUser.fullname"
    centered
    @ok="() => (saveModifiedUser())"
  >
    <a-form  :form="form">
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="نام و نام خانوادگی"
      >
        <a-input
          v-decorator="[
            'fullname',
            {
              rules: [{ required: true, message: 'لطفا نام و نام خانوادگی کاربر را وارد کنید' }],
              initialValue: modifyUser.fullname
            },
            
          ]"

          placeholder="بنویسید"
        />
      </a-form-item>
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="نام کاربری"
      >
        <a-input
          v-decorator="[
            'username',
            {
              rules: [{ required: true, message: 'Please input your name' }],
              initialValue: modifyUser.username
            },
          ]"
          placeholder="Please input your name"
          class="ltr"
        />
      </a-form-item>
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="آدرس ایمیل"
      >
        <a-input
          v-decorator="[
            'email',
            {
              rules: [{ required: true, message: 'لطفا آدرس ایمیل کاربر را وارد کنید' }],
              initialValue: modifyUser.email
            },
          ]"
          class="ltr"
          placeholder="بنویسید"
        />
      </a-form-item>
      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="شماره تلفن"
      >
        <a-input
          v-decorator="[
            'phone_number',
            {
              rules: [{ required: true, message: 'Please input your name' }],
              initialValue: modifyUser.phone_number
            },
          ]"
          placeholder="919123456"
          class="ltr"
        />
      </a-form-item>

      <a-form-item
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        label="گروه کاربری"
      >

        <a-select
          mode="multiple"
          placeholder="User Groups"
          :value="selectedRoles"
          style="width: 100%"
          @change="modifyUserGroup"
        >
          <a-select-option v-for="userGroup in userGroups" :key="userGroup" selected :value="userGroup">
            {{ userGroup }}
          </a-select-option>
        </a-select>

      </a-form-item> 
    </a-form>

  
  </a-modal>

  </a-row>
</template>

<script>
	import Vue from 'vue'
 const data = [
];
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

	import { userService } from '../../services/user.service';
	export default ({
		components: { 
		},
    created(){
      // form layout
      this.$form.layout = 'horizontal'
      
      // set admin user
      this.user = Vue.prototype.$me;

      // load user list
      userService.getUsers().then(response => {    
        if (response.status == 200) {
          return response.json();            
        } else {
          alert('asd');
        }
      }).then((response) => {

        this.data = response.data.users
        var that = this;
    
        this.data.forEach(function(user,key){ 
          
        });
        response.data.groups.forEach(function(user,key){ 
          that.userGroups[key] = user.name;
        });
      }).catch((e) => {
        console.log(e);
      });
    },
		data() {
			return {
        selectedRoles: [],
        checkNick: false,
        formItemLayout,
        formTailLayout,
        form: this.$form.createForm(this, { 
          name: 'dynamic_rule' 
        }),
        modifyUser: {},
        modifyModel: null,
        user: null, 
				data,
				userGroups: {},
				filteredTags: [],
				tagsFilterDropdownVisible: false,
			}
		},
		methods: {
      saveModifiedUser() {

        this.form.validateFields(err => {
          if (!err) {
            var user = {
              id: this.modifyUser.id,
              fullname: this.form.getFieldValue('fullname'),
              username: this.form.getFieldValue('username'),
              email: this.form.getFieldValue('email'),
              phone_number: this.form.getFieldValue('phone_number'),
              fullname: this.form.getFieldValue('fullname'),
              roles: this.selectedRoles
            }

            // load user list
            userService.saveUser(user).then(response => {    
              if (response.status == 200) {
                return response.json();            
              } else {
                alert('asd');
              }
            }).then((response) => {

              console.log(response)
          
            }).catch((e) => {
              console.log(e);
            });
          }
        });
      },
      modifyUserGroup(selectedRoles) {
        this.selectedRoles = selectedRoles
      },
      handleChange(e) {
        this.checkNick = e.target.checked;
        
      },
      displayModifyModal(user) {
        this.modifyUser = user;
        this.selectedRoles = Object.values(user.roles)
        this.modifyModel = true
      },
			toggleSelectedTags(oldTags, tag, checked) {
				let newTags = [...oldTags];
				if (checked) {
				newTags.push(tag);
				} else {
				newTags = newTags.filter(t => t != tag);
				}
				return newTags;
			},
		}
	})

</script>

<style lang="scss">
</style>